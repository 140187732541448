import React from 'react';
import Layout from '../../components/layout/Layout';
import spartanAffiliate from '../../assets/images/SpartanAffiliates.png';
import { HeadFC, Link } from 'gatsby';
const SpartanAffiliatesPage = () => {
  return (
    <Layout>
      <div className="relative w-full  text-center bg-cover bg-center ">
        <div className="px-[15px] md:px-[27px] xl:px-[88px] lg:px-[50px] pb-[80px] contain">
          <h1 className="lg:text-[35px]  text-left text-[21px] leading-[1.2] font-bold font-sofiaBold lg:mt-[40px] mt-[4px] text-black ">
            Join the Spartan Peptides{' '}
            <span className="text-primary">Affiliate Program </span> & Earn
            While You Share
          </h1>
          <div className="flex lg:justify-between lg:flex-row flex-col-reverse  ">
            <div className="lg:w-[65%] w-full  text-left">
              <div className="w-full relative">
                <div className="lg:mt-6 mt-3 aff-text">
                  <p>
                    Are you looking for a rewarding way to earn commissions?
                    Join the Spartan Peptides Affiliate & Ambassador Program and
                    get rewarded for sharing top-quality research peptides.{' '}
                    <br /> <br />
                    As an affiliate, you’ll earn commissions on every sale made
                    through your link—no hassle, just easy earnings while
                    supporting scientific research. Spartan Peptides is known
                    for precision and quality, making it a trusted choice in the
                    industry. <br />
                    <br />
                    Our program offers competitive discounts, clear guidelines,
                    and everything you need to succeed. Ready to partner with
                    Spartan Peptides? Let’s break down the benefits, commission
                    details, and how to get started.
                  </p>
                  <h2 className="lg:text-[30px] text-[18px] uppercase leading-[1.2] mt-5 font-bold font-sofiaBold text-black ">
                    Why Join the Spartan Peptides Affiliate Program?
                  </h2>
                  <div className="h-[5px] mt-[5px]  mb-[20px]  w-[100px] flex items-end bg-primary"></div>
                  <p>
                    The Spartan Peptides Affiliate & Ambassador Program is more
                    than just a way to earn commissions—it’s a partnership built
                    on quality, trust, and innovation in research.
                  </p>
                  <p>What makes the program stand out?</p>
                  <ul>
                    <li>
                      <span>Earn as you promote:</span> Get rewarded with a
                      performance-based commission for every successful
                      referral. The more you share, the more you earn.
                    </li>
                    <li>
                      <span>Exclusive marketing resources:</span> Access
                      high-quality visuals, product details, and promotional
                      materials designed to make marketing effortless.
                    </li>
                    <li>
                      <span>Boost your credibility: </span> Align with a
                      respected brand in the research industry, strengthening
                      your online presence and authority.
                    </li>
                    <li>
                      {' '}
                      <span>Community and support: </span> Connect with
                      like-minded affiliates, share insights, and receive
                      ongoing guidance to maximize your success.
                    </li>
                  </ul>
                  <p>
                    Spartan Peptides is committed to providing top-tier peptides
                    for research and development, and our affiliate program
                    reflects that same dedication to excellence. Do you want to
                    monetize your platform or expand your influence in the
                    scientific community? This program gives you the tools and
                    rewards you to make it happen.
                  </p>
                  <h2 className="lg:text-[30px] text-[18px] font-bold uppercase  leading-[1.2] mt-5 font-sofiaBold text-black ">
                    Commission Structure & Earnings
                  </h2>
                  <div className="h-[5px] mt-[5px]  mb-[20px]  w-[100px] flex items-end bg-primary"></div>
                  <p>
                    Earning with the Spartan Peptides Affiliate Program is
                    simple and rewarding. Our tiered commission model ensures
                    that the more you promote, the more you earn.
                  </p>
                  <p>How does it work?</p>
                  <ul>
                    <li>
                      <span>30% Discount for you: </span> Enjoy a 30% discount
                      code for your personal purchases, allowing you to
                      experience our products firsthand.
                    </li>
                    <li>
                      <span>Promotional code for followers: </span> Share a
                      unique code that gives your followers a 15% of their first
                      purchase, making it easier to try our peptides.
                    </li>
                    <li>
                      <span>Earn 10% commission: </span> For every sale made
                      using your promotional code, you’ll earn a 10% commission
                      on the sale value. This means your earnings can add up
                      quickly as you refer more customers.
                    </li>
                    <li>
                      <span>Flexible profit options:</span> You have the option
                      to use your profits toward future purchases or have them
                      paid out in cash, giving you the flexibility to choose
                      what works for you.
                    </li>
                  </ul>
                  <h3 className="lg:text-[30px] text-[18px] uppercase leading-[1.2] mt-5 text-black font-sofiaBold text-left font-bold">
                    Ready to Join?
                  </h3>
                  <div className="h-[5px] mt-[5px]  mb-[20px]  w-[100px] flex items-end bg-primary"></div>
                  <p>
                    Are you excited to become a part of the Spartan Peptides
                    Affiliate/Ambassador Program and start earning while
                    promoting high-quality peptides? We’d love to have you on
                    board!
                    <br />
                    <br />
                    Click the link below to access the application form and take
                    the first step toward an exciting partnership with Spartan
                    Peptides.
                  </p>
                  <p>We can’t wait to welcome you to our community!</p>
                </div>
              </div>
            </div>
            <div className="lg:w-[30%] w-full">
              <div className="w-full lg:sticky relative lg:top-[10px]">
                <img
                  src={spartanAffiliate}
                  width={300}
                  height={400}
                  alt="SpartanAffiliates"
                  className="md:w-[100%]  w-full mx-auto lg:pt-[50px] md:pt-[30px] pt-[16px] "
                />
                <a
                  href="https://spartanpeptides.postaffiliatepro.com/affiliates/login.php#login"
                  target="_blank"
                  role="button"
                  className="lg:!inline-block hidden shadow-lg aff-background hover:bg-primary hover:shadow-lg hover:border-black hover:transition-all py-2 !my-[10px] w-full !text-[20px] border-primary uppercase border-[2px] text-white font-bold font-sofiaBold px-[40px]"
                >
                  Apply Now
                </a>
              </div>
              <a
                href="https://spartanpeptides.postaffiliatepro.com/affiliates/login.php#login"
                target="_blank"
                role="button"
                className="w-full lg:!hidden !inline-block shadow-lg aff-background hover:bg-primary hover:shadow-lg hover:border-black hover:transition-all py-4 !my-[20px]  !text-[20px] border-primary uppercase border-[2px] text-white font-bold font-sofiaBold px-[40px]"
              >
                Apply Now
              </a>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default SpartanAffiliatesPage;

export const Head: HeadFC = () => {
  return (
    <>
      <title>
        Spartan Peptides Affiliate Program: 10% Commissions + 30% Off | Spartan
      </title>
      <meta
        name="description"
        content="Partner with Spartan Peptides and earn commissions while promoting premium research peptides. Get 10% on sales, 30% off personal orders, and flexible payout options!"
      />
      <meta
        property="og:title"
        content="Spartan Peptides Affiliate Program: 10% Commissions + 30% Off | Spartan"
      />
      <meta
        property="og:description"
        content="Partner with Spartan Peptides and earn commissions while promoting premium research peptides. Get 10% on sales, 30% off personal orders, and flexible payout options!
"
      />
      <meta property="og:type" content="website" />
      <meta
        property="og:image"
        content="https://staging-backend.spartanpeptides.com/wp-content/uploads/2024/09/rankmath-logo.png"
      />
    </>
  );
};
